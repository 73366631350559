<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/services/7-1/B23-20190415_01.jpg">
					<img class="" src="@/assets/images/services/7-1/B23-20190415_02.jpg">
					<img class="" src="@/assets/images/services/7-1/B23-20190415_04.jpg">
					<img class="" src="@/assets/images/services/7-1/B23-20190415_05.jpg">
					<img class="" src="@/assets/images/services/7-1/B23-20190415_06.jpg">
					<img class="" src="@/assets/images/services/7-1/B23-20190415_07.jpg">
				
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"应急响应",
			content:"政府、企业的业务依赖于信息系统，安全事件的发生影响信息系统，应急响应服务控制安全事件发生。",
            cc:"安全评估 应急预案 应急演练 应急响应 信息汇报",
			price:'在线咨询',
			url:require('../../assets/images/services/7-1/190_G_1530778923920.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







